
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import pl from 'date-fns/locale/pl';
import {mapActions, mapGetters, mapState} from "vuex";

import declination from '../../libs/declination';
import {copyToClipboard} from '../../plugins/clipboard';
import {openFlagModal} from "../../plugins/flags";
import {confirmModal} from "../../plugins/modals";
import {formatTimeAgo, VueTimeAgo} from "../../plugins/timeago.js";
import store from "../../store/index";
import {notify} from "../../toast";
import {User} from "../../types/models";
import {nextTick} from "../../vue";
import VueAvatar from '../avatar.vue';
import VueDeleteModal from "../delete-modal.vue";
import VueIcon from "../icon";
import {default as mixins} from '../mixins/user.js';
import VueTags from '../tags.vue';
import VueUserName from "../user-name.vue";
import VueGallery from "./../../components/microblog/gallery.vue";
import VueFlag from './../flags/flag.vue';
import VueButton from './../forms/button.vue';
import VueSelect from './../forms/select.vue';
import VueCommentForm from "./comment-form.vue";
import VueComment from './comment.vue';
import VueForm from './form.vue';
import VuePostGuiderail, {ChildLink} from "./post-guiderail.vue";
import VuePostReview from "./post-review.vue";

export default {
  name: 'post',
  mixins: [mixins],
  components: {
    'vue-gallery': VueGallery,
    VuePostGuiderail,
    'vue-avatar': VueAvatar,
    'vue-button': VueButton,
    'vue-comment': VueComment,
    'vue-comment-form': VueCommentForm,
    'vue-flag': VueFlag,
    'vue-form': VueForm,
    'vue-icon': VueIcon,
    'vue-modal': VueDeleteModal,
    'vue-select': VueSelect,
    'vue-tags': VueTags,
    'vue-timeago': VueTimeAgo,
    'vue-username': VueUserName,
    'vue-post-review': VuePostReview,
  },
  emits: ['reply'],
  props: {
    post: {type: Object, required: true},
    treeItem: {type: Object, required: false},
    uploadMaxSize: {type: Number, default: 20},
    uploadMimes: {type: String},
    treeTopicPostFirst: {type: Boolean, required: false},
    isDraft: {type: Boolean, default: false},
  },
  data() {
    return {
      isProcessing: false,
      isCollapsed: false,
      isCommenting: false,
      commentDefault: {text: '', post_id: this.post.id},
      postDefault: {text: '', html: '', assets: []},
      treeTopicReplyVisible: false,
      galleryImages: [],
      galleryImageIndex: null as number|null,
    };
  },
  created(): void {
    this.$data.isCollapsed = this.hidden;
  },
  mounted(): void {
    if (this.is_mode_tree && !this.post.deleted_at) {
      this.loadVoters(this.post);
    }
    this.resetGalleryImages();
  },
  watch: {
    post(): void {
      this.$data.galleryImages = [];
      nextTick(() => {
        this.resetGalleryImages();
      });
    },
  },
  methods: {
    resetGalleryImages(): void {
      const postContent = this.$refs['postContent'];
      const images = postContent.querySelectorAll('img:not(.img-smile)');
      images.forEach((image, index) => {
        this.$data.galleryImages.push(image.src);
        image.addEventListener('click', () => {
          this.$data.galleryImageIndex = index;
        });
      });
    },
    closeGallery(): void {
      this.$data.galleryImageIndex = null;
    },
    toggleDeletedPost(): void {
      if (!this.postObscured) {
        this.$data.isCollapsed = !this.$data.isCollapsed;
      }
    },
    unfoldChildren(): void {
      store.commit('posts/unfoldChildren', this.$props.post);
    },
    guiderailToggle(expanded: boolean): void {
      if (expanded) {
        store.commit('posts/foldChildren', this.$props.post);
      } else {
        store.commit('posts/unfoldChildren', this.$props.post);
      }
    },
    closePostReview(): void {
      this.post.has_review = false;
    },
    loadVoters(post): void {
      if (!this.$props.isDraft) {
        this.$store.dispatch('posts/loadVoters', post);
      }
    },
    ...mapActions('posts', ['vote', 'accept', 'subscribe', 'unsubscribe', 'loadComments']),
    formatDistanceToNow(date) {
      return formatDistanceToNow(new Date(date), {locale: pl});
    },
    copy(text: string, successMessage: string): void {
      if (copyToClipboard(text)) {
        notify({type: 'success', text: successMessage});
      } else {
        notify({type: 'error', text: 'Nie można skopiować linku. Sprawdź ustawienia przeglądarki.'});
      }
    },
    edit() {
      store.commit('posts/editStart', this.post);
      nextTick(() => (this.$refs.form as VueForm).$refs.markdown.focus());
    },
    comment() {
      this.$data.isCommenting = !this.$data.isCommenting;
      if (this.$data.isCommenting) {
        nextTick(() => (this.$refs['comment-form'] as typeof VueCommentForm).focus());
      }
    },
    deletePostOpenModal(): void {
      (this.$refs['delete-modal'] as typeof VueDeleteModal).open();
    },
    deletePostCloseModalDelete(reasonId: number): void {
      (this.$refs['delete-modal'] as typeof VueDeleteModal)!.close();
      store.dispatch('posts/delete', {post: this.post, reasonId})
        .then(() => this.$data.isCollapsed = true);
    },
    merge() {
      confirmModal({
        message: 'Czy chcesz połaczyć ten post z poprzednim?',
        title: 'Połączyć posty?',
        okLabel: 'Tak, połącz',
      }).then(() => {
        store.dispatch('posts/merge', this.post);
      });
    },
    restore() {
      this.$data.isCollapsed = false;
      store.dispatch('posts/restore', this.post);
    },
    flagPost(): void {
      const post = this.$props.post;
      openFlagModal(post.url, post.metadata);
    },
    replyMentionAuthor(): void {
      this.$emit('reply', this.$props.post);
    },
    replyQuoteContent(): void {
      if (store.getters['topics/is_mode_tree']) {
        this.$data.treeTopicReplyVisible = !this.$data.treeTopicReplyVisible;
        if (this.$data.treeTopicReplyVisible) {
          nextTick(() => {
            this.$refs.topicReply.focus();
          });
        }
      } else {
        this.$emit('reply', this.$props.post, false);
      }
    },
    formSaved(): void {
      this.$data.treeTopicReplyVisible = false;
    },
    copyPostLink(): void {
      this.copy(this.$props.post.url, 'Link do postu znajduje się w schowku.');
    },
    copyPostLinkMarkdown(): void {
      this.copy(markdownLink(this.$props.post.id, this.$props.post.url), 'Link Markdown do postu znajduje się w schowku.');
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('topics', ['reasons']),
    ...mapGetters('user', ['isAuthorized']),
    ...mapGetters('posts', ['posts', 'isLinearized']),
    ...mapGetters('topics', ['topic', 'is_mode_tree', 'is_mode_linear']),
    postTreeTargetId(): number {
      return store.getters['posts/treeTopicPostTargetId'](this.$props.post.id);
    },
    hasPostTreeTarget(): boolean {
      return store.getters['posts/treeTopicPostTargetId'](this.$props.post.id) !== null;
    },
    childrenFolded(): boolean {
      return this.$props.post.childrenFolded;
    },
    postAnswersAuthors(): User[] {
      if (this.$props.treeItem) {
        return this.$props.treeItem.childrenAuthors;
      }
      return [];
    },
    hasChildren(): boolean {
      return this.postAnswersAuthors.length > 0;
    },
    postAnswersAuthorsDistinct(): User[] {
      const map = new Map<number, User>();
      for (const author of this.postAnswersAuthors) {
        map.set(author.id, author);
      }
      return Array.from(map.values());
    },
    postAnswersAuthorsSeeMore(): string {
      const answers = this.postAnswersAuthors.length;
      return `Zobacz ${answers} ${declination(answers, ['odpowiedź', 'odpowiedzi', 'odpowiedzi'])}.`;
    },
    editedTimeAgo() {
      return formatTimeAgo(this.$props.post.updated_at);
    },
    postObscured(): boolean {
      return this.$props.post.type === 'obscured';
    },
    postIndentCssClasses(): string[] {
      if (!this.$props.treeItem) return [];
      const indent = this.$props.treeItem.indent;
      const indentCssClasses = [
        'indent-none', 'indent-1', 'indent-2', 'indent-3', 'indent-4', 'indent-5',
        'indent-6', 'indent-7', 'indent-8', 'indent-9', 'indent-10', 'indent-11',
      ];
      return ['indent', indentCssClasses[indent]];
    },
    guiderailVisible(): boolean {
      return !!this.$props.treeItem;
    },
    linksToParent(): boolean {
      return this.$props.treeItem.linksToParent;
    },
    hasDeeperChildren(): boolean {
      if (this.$props.treeItem) {
        return this.$props.treeItem.hasDeeperChildren;
      }
      return false;
    },
    postSubTreeUrl(): string {
      return this.$props.post.url;
    },
    linkToChild(): ChildLink {
      if (!this.$props.treeItem.linksToChildren) {
        return 'none';
      }
      if (this.childrenFolded) {
        return 'toggle-only';
      }
      return 'toggle-and-link';
    },
    parentLevels(): number[] {
      return this.$props.treeItem.parentLevels;
    },
    postDropdownVisible(): boolean {
      return this.postDropdownItems.length > 0;
    },
    postDropdownItems(): object[] {
      const items = [];
      const post: Post = this.$props.post;
      if (post.permissions.update) {
        items.push({title: 'Edytuj', iconName: 'postEdit', action: this.edit, disabled: post.deleted_at || post.is_editing});
      }
      if (post.permissions.delete) {
        items.push({title: 'Usuń', iconName: 'postDelete', action: this.deletePostOpenModal});
      }
      if (post.is_subscribed) {
        items.push({title: 'Przestań obserwować', iconName: 'postSubscribed', action: () => this.checkAuth(this.unsubscribe, post)});
      } else {
        items.push({title: 'Obserwuj', iconName: 'postSubscribe', action: () => this.checkAuth(this.subscribe, post)});
      }
      if (!post.deleted_at) {
        items.push({title: 'Zgłoś', iconName: 'postReport', action: this.flagPost});
      }
      const canMerge = this.is_mode_linear;
      const mod = post.moderatorPermissions;
      if (mod.delete || mod.update || mod.accept || (mod.merge && canMerge)) {
        items.push({divider: true});
      }
      if (mod.accept) {
        if (post.is_accepted) {
          items.push({title: 'Usuń akceptację jako moderator', iconName: 'postAcceptAccepted', action: () => this.accept(post)});
        } else {
          items.push({title: 'Zaakceptuj jako moderator', iconName: 'postAccept', action: () => this.accept(post)});
        }
      }
      if (mod.update) {
        items.push({title: 'Edytuj jako moderator', iconName: 'postEdit', action: this.edit, disabled: post.deleted_at || post.is_editing});
        items.push({title: 'Historia edycji', iconName: 'postEditHistoryShow', link: '/Forum/Post/Log/' + post.id});
      }
      if (mod.delete) {
        if (post.deleted_at) {
          items.push({title: 'Przywróć', iconName: 'postRestore', action: this.restore});
        } else {
          items.push({title: 'Usuń jako moderator', iconName: 'postDelete', action: this.deletePostOpenModal});
        }
      }
      if (mod.merge && canMerge) {
        items.push({title: 'Połącz z poprzednim', iconName: 'postMergeWithPrevious', action: this.merge, disabled: post.deleted_at || this.isFirstPost});
      }
      return items;
    },
    shareDropdownItems(): object[] {
      const items = [];
      items.push({title: 'Kopiuj link do postu ', iconName: 'postCopyLinkPost', action: this.copyPostLink});
      items.push({title: 'Kopiuj link do postu jako Markdown', iconName: 'postCopyLinkPost', action: this.copyPostLinkMarkdown});
      return items;
    },
    voters() {
      const users = this.post.voters;
      if (!users?.length) {
        return null;
      }
      return users.length > 10 ? users.slice(0, 10).concat('...').join("\n") : users.join("\n");
    },
    otherVoters(): string[]|null {
      if (this.post.voters) {
        return this.post.voters.filter((name: string) => name !== this.user.name);
      }
      return null;
    },
    tags() {
      return this.isFirstPost ? this.topic.tags : [];
    },
    anchor() {
      return `id${this.post.id}`;
    },
    highlight(): boolean {
      return this.post.highlighted;
    },
    totalComments() {
      return this.post.comments_count - Object.keys(this.post.comments).length;
    },
    flags() {
      return store.getters['flags/filter'](this.post.id, 'Coyote\\Post');
    },
    hidden(): boolean {
      return this.post.deleted_at || this.authorBlocked;
    },
    authorBlocked(): boolean {
      return this.post.user_id && store.getters['user/isBlocked'](this.post.user_id);
    },
    isFirstPost(): boolean {
      return this.$props.post.id === this.topic.first_post_id;
    },
    signatureVisible(): boolean {
      if (this.is_mode_tree && !this.isFirstPost) {
        return false;
      }
      return this.$props.post.user && this.$props.post.user.sig;
    },
  },
};

function markdownLink(postId: number, postUrl: string): string {
  return `[#${postId}](${postUrl})`;
}
