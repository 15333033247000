import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["post-review py-2", $options.reviewClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-lg-2 d-none d-lg-block" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 2 /* CLASS */))
}