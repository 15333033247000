import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "box-poll" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  key: 0,
  class: "max-items"
}
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = ["id", "value"]
const _hoisted_7 = ["id", "value"]
const _hoisted_8 = ["for", "textContent"]
const _hoisted_9 = { class: "col-sm-2" }
const _hoisted_10 = { class: "progress" }
const _hoisted_11 = { class: "sr-only" }
const _hoisted_12 = { class: "col-sm-4" }
const _hoisted_13 = {
  key: 0,
  class: "row"
}
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = {
  key: 1,
  class: "row"
}
const _hoisted_16 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_button = _resolveComponent("vue-button")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("strong", null, _toDisplayString($options.pollSync.title), 1 /* TEXT */),
        ($options.pollSync.max_items > 1)
          ? (_openBlock(), _createElementBlock("em", _hoisted_4, " (* możesz oddać maksymalnie " + _toDisplayString($props.poll.max_items) + " " + _toDisplayString(_ctx.declination($props.poll.max_items, ['głos', 'głosy', 'głosów'])) + ") ", 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.pollSync.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: _normalizeClass([{'voted': $options.pollSync.votes.includes(item.id)}, "row"])
      }, [
        _createElementVNode("div", _hoisted_5, [
          ($options.isVoteable)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{'custom-checkbox': $options.pollSync.max_items > 1, 'custom-radio': $options.pollSync.max_items === 1}, "custom-control d-flex flex-nowrap"])
              }, [
                ($options.pollSync.max_items > 1)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      type: "checkbox",
                      id: `item-${item.id}`,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.checkedOptions) = $event)),
                      value: item.id,
                      class: "custom-control-input"
                    }, null, 8 /* PROPS */, _hoisted_6)), [
                      [_vModelCheckbox, $data.checkedOptions]
                    ])
                  : _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 1,
                      id: `item-${item.id}`,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.checkedOptions) = $event)),
                      value: item.id,
                      type: "radio",
                      class: "custom-control-input"
                    }, null, 8 /* PROPS */, _hoisted_7)), [
                      [_vModelRadio, $data.checkedOptions]
                    ]),
                _createElementVNode("label", {
                  for: `item-${item.id}`,
                  class: "custom-control-label",
                  textContent: _toDisplayString(item.text)
                }, null, 8 /* PROPS */, _hoisted_8)
              ], 2 /* CLASS */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(item.text), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: "progress-bar",
              role: "progressbar",
              style: _normalizeStyle(`width: ${$options.percentage(item)}%`)
            }, [
              _createElementVNode("span", _hoisted_11, _toDisplayString($options.percentage(item)) + "%", 1 /* TEXT */)
            ], 4 /* STYLE */)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, _toDisplayString($options.percentage(item)) + "% [" + _toDisplayString(item.total) + "] ", 1 /* TEXT */)
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */)),
    ($options.isVoteable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_vue_button, {
              disabled: $data.isProcessing,
              onClick: $options.vote,
              class: "btn btn-sm btn-primary"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Głosuj")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($options.pollSync.expired)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("p", null, [
              _createElementVNode("em", null, [
                _cache[3] || (_cache[3] = _createTextVNode("Ankieta wygasła ")),
                _createVNode(_component_vue_timeago, {
                  datetime: $options.pollSync.expired_at
                }, null, 8 /* PROPS */, ["datetime"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}