import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_post = _resolveComponent("vue-post")!

  return (_openBlock(), _createBlock(_component_vue_post, {
    post: $props.post,
    "tree-item": $props.treeItem,
    onReply: $options.reply,
    "tree-topic-post-first": $props.treeTopicPostFirst,
    "is-draft": $props.isDraft
  }, null, 8 /* PROPS */, ["post", "tree-item", "onReply", "tree-topic-post-first", "is-draft"]))
}